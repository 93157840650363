<template>
  <div>
    <div style="background:#1018af">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>

    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link :to="{ path: '/components/web/solutions/case01' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>企业综合管理</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case02' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>能源石化业务管理</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case03' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>国际运营商链路优化</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case04' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>04</div>
                <div>大数据应用</div>
              </div>
            </li>
          </router-link>
          <!-- <router-link :to="{ path: '/components/web/solutions/case05' }">
            <li class="tabStyleLi">
              <div class="tabStyle" style="background:#3246F4"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>05</div>
                <div>系统技术运维与支持</div>
              </div>
            </li>
          </router-link> -->
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">首页</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">产品及解决方案</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133"
            >系统技术运维与支持</span
          ></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div style="height:30px"></div>
    <div style="width:1200px; margin:0 auto;">
      <div style="margin-bottom:30px">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'solutions',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/33.jpg'),
      corporateVision1: require('../../../assets/aboutus/corporateVision-img1.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/aboutus/corporateVision-img3.png'),
      img1: require('../../../assets/index/img1.png'),
      img2: require('../../../assets/index/img2.png'),
      img3: require('../../../assets/index/img3.png'),
      case01: require('../../../assets/chanpin/case01.jpg'),
      case02: require('../../../assets/chanpin/case02.jpg'),
      case03: require('../../../assets/chanpin/case03.jpg'),
      case04: require('../../../assets/chanpin/case04.png'),
      case05: require('../../../assets/chanpin/case05.png'),
      case06: require('../../../assets/chanpin/case06.png'),
      case07: require('../../../assets/chanpin/case07.png'),
      case08: require('../../../assets/chanpin/case08.png'),
      case09: require('../../../assets/chanpin/case09.png'),
      case10: require('../../../assets/chanpin/case10.png'),
      case11: require('../../../assets/chanpin/case11.png'),
      case12: require('../../../assets/chanpin/case12.png'),
      showText: false,
      showText2: false,
      showText3: false,
      showText4: false,
      showText5: false,
      showText6: false,
      showText7: false,
      showText8: false,
      showText9: false,
      showText10: false,
      showText11: false,
      showText12: false,
    }
  },
  mounted() {
    this.$bus.$emit('getCurNav', 'solutions')
  },
  methods: {
    showTextFun(num) {
      if (num == 1) {
        this.showText = true
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 2) {
        this.showText = false
        this.showText2 = true
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 3) {
        this.showText = false
        this.showText2 = false
        this.showText3 = true
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 4) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = true
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 5) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = true
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 6) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = true
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 7) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = true
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 8) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = true
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 9) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = true
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
      } else if (num == 10) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = true
        this.showText11 = false
        this.showText12 = false
      } else if (num == 11) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = true
        this.showText12 = false
      } else if (num == 12) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = true
      }
    },
    hideTextFun() {
      this.showText = false
      this.showText2 = false
      this.showText3 = false
      this.showText4 = false
      this.showText5 = false
      this.showText6 = false
      this.showText7 = false
      this.showText8 = false
      this.showText9 = false
      this.showText10 = false
      this.showText11 = false
      this.showText12 = false
    },
  },
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 220px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.jjfaTitle {
  height: 20px;
  font-size: 21px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #2c3dd6;
  line-height: 37px;
  margin: 5px 0;
}
.jjfamiaoshu {
  height: 16px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #666666;
  line-height: 37px;
}
.grid-content {
  text-align: center;
  height: 250px;
  line-height: 50px;
  padding-top: 50px;
}
.grid-content:hover {
  cursor: pointer;
  background: #f6f8f9;
  height: 250px;
}
.grid-content2 {
  position: relative;
  cursor: pointer;
}
.imgText {
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #ffffff;
  margin: 10px;
}
.hzgs {
  height: 23px;
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #475aff;
  line-height: 37px;
}
.el-row {
  margin: 0px !important;
}
</style>
